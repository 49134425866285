import { render, staticRenderFns } from "./Tips.vue?vue&type=template&id=006c3f05&"
import script from "./Tips.vue?vue&type=script&lang=js&"
export * from "./Tips.vue?vue&type=script&lang=js&"
import style0 from "./Tips.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\Hong-Dev\\h-frontend\\main\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('006c3f05')) {
      api.createRecord('006c3f05', component.options)
    } else {
      api.reload('006c3f05', component.options)
    }
    module.hot.accept("./Tips.vue?vue&type=template&id=006c3f05&", function () {
      api.rerender('006c3f05', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/Layout/components/Tips.vue"
export default component.exports